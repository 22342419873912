.button[data-v-e7b2fc26] {
  cursor: pointer;
  display: inline-block;
  padding: 3px;
}
.flip-list-move[data-v-e7b2fc26] {
  transition: transform 1s;
}
.main-player__wrap[data-v-e7b2fc26] {
  position: fixed;
  bottom: -100%;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 9999;
  transition: bottom ease-in-out .3s;
}
.eco-theme .main-player__wrap[data-v-e7b2fc26] {
  background: #000;
  color: #a7a7a7;
}
.main-player__wrap.full[data-v-e7b2fc26] {
  height: calc(100% - 100px);
}
.main-player__wrap.show[data-v-e7b2fc26] {
  bottom: 0;
}
.main-player__wrap.active[data-v-e7b2fc26] {
  bottom: 0;
}
.main-player[data-v-e7b2fc26] {
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
  position: relative;
}
.eco-theme .main-player[data-v-e7b2fc26] {
  border-top: 1px solid #777;
  border-bottom: 1px solid #777;
}
.min .main-player[data-v-e7b2fc26] {
  max-height: 0;
  padding: 0;
  overflow: hidden;
}
.main-player__controls[data-v-e7b2fc26] {
  border: 1px solid #e2e2e2;
  border-bottom: 0;
  border-left: 0;
  background: #fff;
  position: absolute;
  bottom: 100%;
  left: 0;
  font-size: 0;
}
.eco-theme .main-player__controls[data-v-e7b2fc26] {
  background: #000;
  color: #a7a7a7;
  border: 1px solid #777;
}
.main-player__controls span[data-v-e7b2fc26] {
  cursor: pointer;
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  font-size: 14px;
  display: inline-block;
  transition: background-color ease .3s;
}
.main-player__controls span[data-v-e7b2fc26]:hover,
.main-player__controls span.active[data-v-e7b2fc26] {
  background: #22b14c;
}
.main-player__controls span[data-v-e7b2fc26]:first-child {
  border-right: 1px solid #e2e2e2;
}
.eco-theme .main-player__controls span[data-v-e7b2fc26]:first-child {
  border-right: 1px solid #777;
}
.main-player__name[data-v-e7b2fc26] {
  font-size: 16px;
  padding: 10px;
  cursor: pointer;
}
.main-player-playlist[data-v-e7b2fc26] {
  width: 100%;
  padding: 10px;
  max-height: 90px;
  overflow-y: auto;
}
.full .main-player-playlist[data-v-e7b2fc26] {
  max-height: 100%;
}
.min .main-player-playlist[data-v-e7b2fc26] {
  max-height: 0;
  padding: 0;
  overflow: hidden;
}
.main-player-playlist__item[data-v-e7b2fc26] {
  font-size: 16px;
  padding: 5px 0;
}
.main-player-playlist__item.active[data-v-e7b2fc26] {
  font-weight: bold;
}
.animation[data-v-e7b2fc26] {
  position: relative;
  width: 0;
  overflow: hidden;
  height: 20px;
  display: inline-block;
  transition: width ease .3s;
}
.animation.show[data-v-e7b2fc26] {
  width: 40px;
}
.loader-3[data-v-e7b2fc26] {
  width: 40px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: -5px auto 0;
  font-size: 0;
  text-align: center;
}
.loader-3 div[data-v-e7b2fc26] {
  background-color: #000;
  margin-left: 2px;
  height: 100%;
  width: 2px;
  display: inline-block;
  animation: loader-3-data-v-e7b2fc26 1s infinite ease-in-out;
}
.eco-theme .loader-3 div[data-v-e7b2fc26] {
  background-color: #a7a7a7;
}
.loader-3 .item-2[data-v-e7b2fc26] {
  animation-delay: -0.8s;
}
.loader-3 .item-3[data-v-e7b2fc26] {
  animation-delay: -0.4s;
}
.loader-3 .item-4[data-v-e7b2fc26] {
  animation-delay: -0.6s;
}
.loader-3 .item-5[data-v-e7b2fc26] {
  animation-delay: -0.2s;
}
@keyframes loader-3-data-v-e7b2fc26 {
0%, 40% {
    transform: scaleY(0.4);
}
100% {
    transform: scaleY(0.6);
}
20% {
    transform: scaleY(1);
}
}

